var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModalTemplate',{attrs:{"showModal":_vm.showModal,"width":"630px"},on:{"closeModal":_vm.closeModal},scopedSlots:_vm._u([{key:"headerModal",fn:function(){return [_c('div',{staticClass:"is-flex is-justify-content-space-between is-align-items-center"},[_c('p',{staticClass:"averta-bold label-18 ml-3"},[_vm._v("Panduan Jadwal")]),_c('div',{staticClass:"is-clickable",on:{"click":_vm.closeModal}},[_c('b-icon',{attrs:{"icon":"close"}})],1)])]},proxy:true},{key:"bodyModal",fn:function(){return [(_vm.isLoading && _vm.dataGuide.length === 0)?_c('div',{staticClass:"mt-4"},[_c('SpinnerIcon')],1):_c('div',{staticClass:"is-flex pb-3"},[_c('div',{staticClass:"type-sesi-survey-wrapper pl-4 py-4 pr-0 mr-2"},[_c('p',{staticClass:"averta-black label-16 mb-3"},[_vm._v("Jenis Sesi Survey")]),_c('div',{staticClass:"height-list-body-guide border-right-1-grey-13 pr-5"},_vm._l((_vm.dataGuide),function(item,index){return _c('div',{key:`type-survey-${index}`,staticClass:"list-type-survey flex-center-vertical is-justify-content-space-between",class:{'active': _vm.selected.id === item.id},on:{"click":() => _vm.onClickTypeSurvey(item)}},[_c('p',{staticClass:"label-14",class:{
                'averta-bold': _vm.selected.id === item.id,
                'color-grey': _vm.selected.id !== item.id
              }},[_vm._v(" "+_vm._s(item.duration)+" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"flex-center"},[_c('ChevronIcon',{attrs:{"color":_vm.selected.id === item.id ? '#E10009' : '#868686'}})],1)])}),0)]),_c('div',{staticClass:"list-sesi-survey p-4"},[_c('p',{staticClass:"averta-black label-16 mb-3"},[_vm._v("Daftar Sesi Survey")]),(_vm.selected && _vm.selected.data.length > 0)?_c('div',{staticClass:"height-list-body-guide c-w-100"},_vm._l((_vm.selected.data),function(item,index){return _c('div',{key:`sesi-${index}`,staticClass:"list-sesi"},[_c('div',{staticClass:"name-sesi border-time-sesi flex-center-vertical border-left-sesi-1",class:{
                'border-top-sesi-1': index === 0,
                'border-top-left-radius': index === 0,
                'border-bottom-left-radius': index === _vm.selected.data.length - 1,
                'bg-grey-8 is-uppercase flex-center': item.break
              }},[_c('p',{staticClass:"averta-bold"},[_vm._v(_vm._s(item.name))])]),_c('div',{staticClass:"time-sesi border-time-sesi flex-center-vertical",class:{
                'border-top-sesi-1': index === 0,
                'border-top-right-radius': index === 0,
                'border-bottom-right-radius': index === _vm.selected.data.length - 1,
                'bg-grey-8': item.break
              }},[_c('p',[_vm._v(_vm._s(item.start_time)+" - "+_vm._s(item.end_time))])])])}),0):_vm._e()])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }