<template>
  <ModalTemplate :showModal="showModal" width="630px" @closeModal="closeModal">
    <template v-slot:headerModal>
      <div class="is-flex is-justify-content-space-between is-align-items-center">
        <p class="averta-bold label-18 ml-3">Panduan Jadwal</p>
        <div class="is-clickable" @click="closeModal">
          <b-icon icon="close"></b-icon>
        </div>
      </div>
    </template>
    <template v-slot:bodyModal>
      <div v-if="isLoading && dataGuide.length === 0" class="mt-4">
        <SpinnerIcon />
      </div>
      <div v-else class="is-flex pb-3">
        <div class="type-sesi-survey-wrapper pl-4 py-4 pr-0 mr-2">
          <p class="averta-black label-16 mb-3">Jenis Sesi Survey</p>
          <div class="height-list-body-guide border-right-1-grey-13 pr-5">
            <div
              v-for="(item, index) in dataGuide"
              :key="`type-survey-${index}`"
              class="list-type-survey flex-center-vertical is-justify-content-space-between"
              :class="{'active': selected.id === item.id}"
              @click="() => onClickTypeSurvey(item)"
            >
              <p
                class="label-14"
                :class="{
                  'averta-bold': selected.id === item.id,
                  'color-grey': selected.id !== item.id
                }"
              >
                {{item.duration}} {{ item.name }}
              </p>
              <div class="flex-center">
                <ChevronIcon :color="selected.id === item.id ? '#E10009' : '#868686'" />
              </div>
            </div>
          </div>
        </div>
        <div class="list-sesi-survey p-4">
          <p class="averta-black label-16 mb-3">Daftar Sesi Survey</p>
          <div class="height-list-body-guide c-w-100" v-if="selected && selected.data.length > 0">
            <div class="list-sesi" v-for="(item, index) in selected.data" :key="`sesi-${index}`">
              <div
                class="name-sesi border-time-sesi flex-center-vertical border-left-sesi-1"
                :class="{
                  'border-top-sesi-1': index === 0,
                  'border-top-left-radius': index === 0,
                  'border-bottom-left-radius': index === selected.data.length - 1,
                  'bg-grey-8 is-uppercase flex-center': item.break
                }"
              >
                <p class="averta-bold">{{item.name}}</p>
              </div>
              <div
                class="time-sesi border-time-sesi flex-center-vertical"
                :class="{
                  'border-top-sesi-1': index === 0,
                  'border-top-right-radius': index === 0,
                  'border-bottom-right-radius': index === selected.data.length - 1,
                  'bg-grey-8': item.break
                }"
              >
                <p>{{item.start_time}} - {{item.end_time}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </ModalTemplate>
</template>

<script>
import { mapGetters } from 'vuex'
import { alertErrorMessage } from '@/helpers'

export default {
  name: 'ModalSelectConsultant',
  components: {
    ModalTemplate: () => import('@/components/modal/ModalTemplate'),
    SpinnerIcon: () => import('@/components/icons/SpinnerIcon'),
    ChevronIcon: () => import('@/components/icons/ChevronIcon')
  },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataGuide: [],
      isLoading: false,
      showModal: false,
      selected: null
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  methods: {
    closeModal () {
      this.showModal = false
      this.$emit('closeModal')
    },
    onClickTypeSurvey (data) {
      this.selected = data
    },
    getGuideSesi () {
      this.isLoading = true
      this.$store
        .dispatch('monitoring/getGuideSesi')
        .then((response) => {
          const res = response.data.data
          this.isLoading = false
          this.dataGuide = res
          this.selected = res[0]
        })
        .catch((err) => {
          this.isLoading = false
          alertErrorMessage(err)
        })
    }
  },
  mounted () {
    this.getGuideSesi()
  },
  watch: {
    open (val) {
      this.showModal = val
      if (val && this.dataGuide.length < 1) {
        this.getGuideSesi()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';
.type-sesi-survey-wrapper {
  flex: 1;
}

.list-type-survey {
  background: #fafafa;
  border-radius: 100px;
  padding: 12px 15px;
  margin-bottom: 13px;
  cursor: pointer;

  &.active {
    background: #FFF5F6;
    color: $color-red-1;
  }
}

.height-list-body-guide {
  height: 300px;
  overflow: auto;
}

.list-sesi-survey {
  flex: 1.3;
}

.list-sesi {
  width: 100%;
  display: flex;
}

.name-sesi {
  height: 50px;
  width: 30%;
  background: #FBEBEA;
}

.time-sesi {
  height: 50px;
  width: 70%;
}

.border-time-sesi {
  border: 1px solid #9A9A9A;
  border-left-width: 0;
  border-top-width: 0;
  padding: 0 15px;
}

.border-left-sesi-1 {
  border-left: 1px solid #9A9A9A !important;
}

.border-top-sesi-1 {
  border-top: 1px solid #9A9A9A !important;
}

.border-top-right-radius {
  border-top-right-radius: 10px;
}

.border-top-left-radius {
  border-top-left-radius: 10px;
}

.border-bottom-right-radius {
  border-bottom-right-radius: 10px;
}

.border-bottom-left-radius {
  border-bottom-left-radius: 10px;
}
</style>
